$setting-item-top-distance: 20px;

.item-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    color: $light-color1;
    white-space: nowrap;
    &:not([disabled]):active {
        background-color: $light-active-color1;
    }
}

// 系统设置
.s-list {
    margin: $left-right-distance;
    margin-top: 10px;
    margin-bottom: 20px;
    &:first-child {
        margin-top: $setting-item-top-distance;
    }
    .s-item {
        @extend .item-style;
        & > div:last-child {
            color: $light-color2;
            font-size: t.px2rem(13px);
            .sport-switch {
                margin-left: 10px;
            }
            svg {
                fill: $light-color3;
            }
        }
        .sport-svg-wrap.s-item-check svg {
            width: 18px;
            height: 18px;
            vertical-align: -0.3em;
            transition: all 0.2s;
            margin-left: 10px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid $light-color2;
            fill: transparent;
        }
        .curr .sport-svg-wrap.s-item-check svg {
            opacity: 1;
            border-width: 0;
            fill: $light-theme-color;
        }
    }
}

// 盘口设置
.cap-list {
    border-radius: 8px;
    overflow: hidden;
    margin: $left-right-distance;
    margin-top: 10px;
    margin-bottom: $setting-item-top-distance;
    background-color: #fff;
    &:first-child {
        margin-top: $setting-item-top-distance;
    }
    .cap-item {
        @extend .item-style;
        margin-bottom: 0;
        div.cap-item-circle {
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid $light-color2;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            box-sizing: border-box;
            transition: all .2s;
            display: inline-block;
        }
        div.cap-item-circle.curr {
            border: 4px solid $light-theme-color;
        }
    }
}

.theme-color {
    color: $light-theme-color!important;
}
.s-list-title-1 {
    color: #202939;
    font-size: t.px2rem(16px);
    font-weight: bold;
}
.s-list-title-2 {
    color: #202939;
    font-weight: bold;
}
.padding-2 > li {
    padding-left: 30px!important;
    padding-right: 30px!important;
}
.padding-3 > li  {
    padding-left: 40px!important;
}
.padding-4 > li  {
    padding-left: 50px!important;
    padding-right: 30px!important;
}
.border-bottom {
    border-bottom: 1px solid #ddd;
}
.push-setting {
    margin-top: $setting-item-top-distance;
    border-radius: 8px;
    ul, li {
        margin-bottom: 0!important;
        margin-top: 0!important;
        border-radius: 0!important;
        overflow: hidden;
    }
    & > ul:first-child {
        border-top-left-radius: 8px!important;
        border-top-right-radius: 8px!important;
    }
    & > ul:last-child {
        border-bottom-left-radius: 8px!important;
        border-bottom-right-radius: 8px!important;
    }
}

// 联赛筛选
.sport-filter-wrap {
    width: 100%;
    position: fixed;
    left: 0;
    top: t.px2rem($header-height);
    padding: $left-right-distance-small;
    padding-top: 8px;
    padding-bottom: 6px;
    background-color: #fff;
    box-shadow: 0 3px 3px #ececec;
    .s-list {
        margin: 0;
    }
    .s-item {
        margin-top: 4px;
        margin-bottom: 0;
        height: 30px;
        line-height: 30px;
        .sport-svg-wrap.s-item-check svg {
            width: t.px2rem(14px);
            height: t.px2rem(14px);
            vertical-align: -0.2em;
        }
    }
    .input__wrap input {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: t.px2rem(40px);
        border-radius: 18px;
        color: #222;
    }
    svg {
        fill: #999;
        width: t.px2rem(14px);
        height: t.px2rem(14px);
        vertical-align: -0.04rem;
    }
}
.filter-list-wrap {
    margin-top: 82px;
    & > h4 {
        padding: $left-right-distance;
        color: $light-color1;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .s-list {
        margin: 0;
    }
    .s-item {
        margin-bottom: 0;
        border-radius: 0;
        height: 30px;
        line-height: 30px;
        padding-right: 30px;
        color: $light-color1;
        .sport-svg-wrap.s-item-check svg {
            width: 14px;
            height: 14px;
            vertical-align: -0.1em;
        }
        span {
            vertical-align: middle;
        }
        & > div {
            pointer-events: none;
        }
        & > div > img {
            width: 22px;
            height: 22px;
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}

// 自定义快捷键
.common-distance {
    margin: $left-right-distance;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
}
.fast-momey {
    & > div > img {
        width: 100%;
        vertical-align: top;
    }
    .fast-set-content {
        padding: 10px 20px 20px;
        background-color: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 20px;
        & > p {
            color: $light-color2;
            font-weight: bold;
        }
    }
    .fast-set-list li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        & > div:first-child {
            font-size: 16px;
            font-weight: bold;
            color: $light-color1;
        }
        .input__wrap {
            width: 80px;
            input {
                padding: 10px 12px;
                border-color: #BCBEC3;
            }
        }
    }
}