
.search-list-wrap {
    color: #999;
    background-color: #fff;
    line-height: 1;
    margin-bottom: 10px;
    h4 {
        color: #666;
    }
    .search-list-icon {
        margin-right: 4px;
        svg {
            width: 16px;
            fill: #ddd;
        }
    }
    .search-list-clear-icon {
        position: absolute;
        height: 12px;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
        svg {
            width: 12px;
            height: 12px;
            fill: #ddd;
            vertical-align: top;
        }
    }


}
.search-result-wrap {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    >.search-sport-tabs {
        width: 100%;
        height: 1rem;
        overflow-x:scroll;
        flex-wrap: nowrap;
        display: flex;
        background-color: #00A6FF;
        color: #B4E4FE;
        font-size: t.px2rem(12px);
        margin-top: -1px; //修正上方有點白邊的問題
        >.search-sport-tab {
            white-space: nowrap;
            padding: 12px 20px;
            &.selected {
                color: #fff;
                border-bottom: solid 3px #fff;
            }
        }
    }
    >.search-result-lists {
        width: 100%;
        display: flex;
        background-color: #EFEFF4;
        flex-direction: column;
        >.search-league-wrapper {
            width:100%;
            >.search-league-row {
                width:100%;
                display: flex;
                flex-direction: row;
                padding:5px;
                >.search-league-name{
                    margin-left: 5px;
                    color: #666666;
                    font-size: t.px2rem(13px);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                img {
                    margin-left: 5px;
                    height: 18px;
                }
            }
            >.search-events-wrapper {
                width:100%;
                background-color: #fff;
                padding: 5px 20px;
                display: flex;
                flex-direction: column;
                >.search-event-wrapper {
                    width:100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin:10px 0;
                    >.search-event-left{
                        display: flex;
                        flex-direction: column;
                        >.search-event-team-row{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            color: #000000;
                            font-size: t.px2rem(13px);
                            font-weight: bold;
                            >.search-event-team-vs {
                                margin: 0 t.px2rem(5px);
                            }
                        }
                        >.search-event-datetime-row{
                            color: #999999;
                            font-size: t.px2rem(12px);
                            margin-top: 5px;
                        }
                    }
                    >.search-event-right{
                        width: 50px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        svg {
                            width: t.px2rem(20px);
                            fill: #999999;
                        }
                    }
                }
            }
        }

    }
    .search-highlight {
        color: #00A6FF !important;
    }
}

.search-history-title {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;

    > .search-history-title-text {
        font-size: 14px;
        color: #333333;
    }
}
.search-recommend {
    padding: 16px 16px;
    background-color: #fff;
    > .search-recommend-title-text {
        font-size: 14px;
        color: #333333;
    }
}
.search-list-2 {
    padding-bottom: 10px;
    & > li {
        position: relative;
        padding: 5px 16px;
        font-size: t.px2rem(12px);
    }
}
.search-list-3 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -1.11111%;
    margin-right: -1.11111%;
    overflow: hidden;
    color: #999;
    & > li {
        margin: 0 1.11111%;
        margin-bottom: 10px;
        & > span {
            display: block;
            border-radius: 6px;
            background-color: #ebebeb;
            padding: 12px 16px;
            font-size: 12px;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.search-list-4 {
    margin: 10px 10px;
    & > li {
        position: relative;
        padding: 10px 14px;
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        & > p {
            font-size: t.px2rem(12px);
            margin-top: 10px;
            color: #999;
        }
        & > .search-result-tag {
            font-size: t.px2rem(12px);
            position: absolute;
            right: 10px;
            top: 12px;
        }
    }
}

.no-result {
    padding-top: 10%;
    & > p {
        font-size: 12px;
        margin-top: 10px;
        padding-bottom: 30px;
    }
}