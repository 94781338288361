.vendor-notice-small-prompt {
    font-size: t.px2rem(12px);
    color: #BCBEC3;
    margin-bottom: 8px;
    text-align: center;
}
.notice-wrapper {
    .vendor-notice-item {
        background: #fff;
        border-radius: 8px;
        padding: t.px2rem(18px);
        font-weight: normal;
        font-size: t.px2rem(12px);
        margin-bottom: 14px;
        & > h4 {
            font-weight: normal;
            margin-bottom: 0.4rem;
        }
        & > .vendor-notice-con {
            font-weight: normal;
            line-height: 1.7;
            color: #999;
        }
    }
}