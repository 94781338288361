@import "iconfont-variables";

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$className:"icon" !default;

//各數字的字碼
$num0: "\eb1f";
$num1: "\caf2e";
$num2: "\a3eda";
$num3: "\e2fae";
$num4: "\4daec";
$num5: "\d39a";
$num6: "\6fe21";
$num7: "\e31da";
$num8: "\de31e";
$num9: "\bf3b9";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?v8viu7');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?v8viu7#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?v8viu7') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?v8viu7') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?v8viu7##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

//$classNames = css類的前綴，每個數字都有10個
@each $thisClassName, $i in $classNames {
  //生成 css類 前綴 定義，引用字體
  [class^="#{$thisClassName}-"], [class*=" #{$thisClassName}-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //$numberNames = css類的後綴，每個數字都有10個
  //把 前綴 後綴 接起來 設置 指定數字的字碼
  //這樣每個數字都有 10x10=100 種可能的class名
  @if $i == 0 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 0 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num0;
        }
      }
    }
  }

  @if $i == 1 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 1 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num1;
        }
      }
    }
  }

  @if $i == 2 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 2 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num2;
        }
      }
    }
  }

  @if $i == 3 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 3 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num3;
        }
      }
    }
  }

  @if $i == 4 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 4 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num4;
        }
      }
    }
  }

  @if $i == 5 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 5 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num5;
        }
      }
    }
  }

  @if $i == 6 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 6 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num6;
        }
      }
    }
  }

  @if $i == 7 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 7 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num7;
        }
      }
    }
  }

  @if $i == 8 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 8 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num8;
        }
      }
    }
  }

  @if $i == 9 {
    @each $thisNumberName, $j in $numberNames {
      @if $j == 9 {
        .#{$thisClassName}-#{$thisNumberName}:before {
          content: $num9;
        }
      }
    }
  }

}