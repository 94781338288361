.doubleElevenModal {
	position: relative;
}
.doubleElevenModal .t-suspend-button {
	position: fixed;
	top: calc(100vh - 5.9rem);
	right: 5px;
	width: 2.9rem;
	z-index: 101;
}
.doubleElevenModal .t-suspend-button img {
	width: 100%;
	height: 100%;
}
.doubleElevenModal .t-suspend-button-animate {
	transition-duration: .4s;
}
.doubleElevenModal .t-suspend-close {
	width: 0.48rem;
	height: 0.48rem;
	position: absolute;
	right: 10px;
	top: 10px;
	opacity: .6;
}
