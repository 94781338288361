// tabsOval
.tabsOval-nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    background: $light-theme-color; 
}
.tabsOval-extra-content {
    color: #fff;
    svg{
        fill: #fff;
    }
}
.tabsOval-nav-list {
    display: flex;
    position: relative;
    transition: transform 0.3s;
    width: 100%;
    color: #ccedff;
    background: #0ea0f0;
    border-radius: 50px;
    .tabsOval-tab {
        flex: 1;
        text-align: center;
        color: #bcbec3;
        margin: 2px;
        &.tabsOval-tab-active {
            color: $light-theme-color;
        }
    }
}

.tabsOval-nav-measure,
.tabsOval-nav-wrap {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    width: 200px;
}
.tabsOval-ink-bar {
    background: #ffffff;
    height: 85%;
    top: 50%;
    transform: translateY(-50%);
}

.tabsOval-top .tabsOval-ink-bar {
    bottom: 0;
}
// .tabsOval-top .tabsOval-ink-bar,
// .tabsOval-bottom .tabsOval-ink-bar {
//     height: 3px;
// }
.tabsOval-ink-bar-animated {
    transition: all 0.3s;
}
.tabsOval-ink-bar {
    position: absolute;
    // background: $light-theme-color;
    pointer-events: none;
    height: 100% !important;
    border-radius: 16px;
    z-index: -1;
}
.tabsOval-tab-btn {
    padding: 7px 0;

    &:focus {
        outline: none;
    }
}

// tabs login
.tabsOvalLogin-tabpane,
.tabsOval-tabpane {
    &:focus {
        outline: none;
    }
}
.tabsOvalLogin-nav-list {
    display: flex;
    position: relative;
    transition: transform 0.3s;
    width: 100%;
    .tabsOvalLogin-tab {
        flex: 1;
        text-align: center;
        color: #bcbec3;
        margin: 2px;
        &.tabsOvalLogin-tab-active {
            color: #ffffff;
        }
    }

    .tabsOvalLogin-ink-bar {
        background: $light-theme-color;
    }
}
.tabsOvalLogin-nav {
    display: flex;
    flex: none;
    position: relative;
    background: #0000000a;
    border-radius: 50px;
    width: 200px;
    margin: 0 auto;
}
.tabsOvalLogin-nav-measure,
.tabsOvalLogin-nav-wrap {
    position: relative;
    display: inline-block;
    flex: auto;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
}
.tabsOvalLogin-top .tabsOvalLogin-ink-bar {
    bottom: 0;
}
.tabsOvalLogin-ink-bar-animated {
    transition: all 0.3s;
}
.tabsOvalLogin-ink-bar {
    position: absolute;
    // background: $light-theme-color;
    pointer-events: none;
    height: 100%;
    border-radius: 16px;
    z-index: -1;
}
.tabsOvalLogin-tab-btn {
    padding: 7px 0;

    &:focus {
        outline: none;
    }
}

// tabsNormal
.tabsNormal-nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    // padding: 10px 0;
    background: $light-theme-color;
}
.tabsNormal-nav-list {
    display: flex;
    position: relative;
    transition: transform 0.3s;
    width: 100%;
    color: #ccedff;
    .tabsNormal-tab {
        flex: 1;
        text-align: center;
        color: #CCEDFF;
        display: flex;
        justify-content: center;
        &.tabsNormal-tab-active {
            color: #fff;
        }
    }
}

.tabsNormal-nav-measure,
.tabsNormal-nav-wrap {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    width: 100%;
}
.tabsNormal-ink-bar {
    background: #ffffff;
    height: 3px;
}

.tabsNormal-top .tabsNormal-ink-bar {
    display: none;
    bottom: 0;
}
// .tabsOval-top .tabsOval-ink-bar,
// .tabsOval-bottom .tabsOval-ink-bar {
//     height: 3px;
// }
.tabsNormal-ink-bar-animated {
    transition: all 0.3s;
}
.tabsNormal-ink-bar {
    position: absolute;
    pointer-events: none;
    z-index: -1;
}
.tabsNormal-tab-btn {
    padding: 7px 0;
    width: 60px;
    &:focus {
        outline: none;
    }
}

.tabsNormal-tab-active .tabsNormal-tab-btn{
    border-bottom: 3px solid #fff;   
}

.tabsNormal-tabpane{
    &:focus {
        outline: none;
    }
}


.tutorial-tabs .tabsNormal-tab-btn{
    width: 100%;
}