@function px2rem428 ($px) {
  $rem: 42.8px; //適配這個活動頁的mockup 頁寬為428px => 42.8  (一般為375 => 37.5)
  @return calc($px / $rem) + rem;
}


.ann14-container {
  width: 100vw;

  .ann14-top {
    width: 100vw;
    height: px2rem428(1116px);
    background-image: url($base-path + '/img/events/ann14/BG_Mobile_1.webp');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;


    .ann14-top-banner {
      width: calc(100vw - 0.748rem); //16px*2 => 0.748rem
      margin: px2rem428(32px) px2rem428(16px) px2rem428(20px) px2rem428(16px);
    }
    .ann14-top-text-container {
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .ann14-top-text {
        width: px2rem428(216px);
        height: px2rem428(41px);
        font: normal normal 300 px2rem428(13px)/px2rem428(17px) Microsoft YaHei;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url($base-path + '/img/events/ann14/Text-Below-Title.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    .ann14-top-countdown-container {
      width: 100vw;
      margin-top: px2rem428(35px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .ann14-top-countdown-numbers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ann14-top-countdown-number {
          width: px2rem428(44px);
          height: px2rem428(60px);
          font: normal normal bold px2rem428(38px)/px2rem428(46px) Roboto;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-image: url($base-path + '/img/events/ann14/Countdown-Timer.webp');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        .ann14-top-countdown-sep {
          width: px2rem428(14px);
          height: px2rem428(60px);
          font: normal normal normal px2rem428(30px)/px2rem428(40px) Microsoft YaHei;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
      .ann14-top-countdown-words {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ann14-top-countdown-word {
          width: px2rem428(88px);
          height: px2rem428(15px);
          margin-top: px2rem428(3px);
          font: normal normal bold px2rem428(11px)/px2rem428(15px) Microsoft YaHei;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .ann14-top-countdown-sep {
          width: px2rem428(14px);
          height: px2rem428(15px);
        }
      }
    }
    .ann14-top-portals-container{
      position: relative;
      height: px2rem428(463px);
      .ann14-top-portal {
        background-image: url($base-path + '/img/events/ann14/Popping/Portal.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        width: px2rem428(165px);
        height: px2rem428(177px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        &.ann14-top-portal-1 {
          top: px2rem428(14px);
          left: px2rem428(22px);
        }
        &.ann14-top-portal-2 {
          top: px2rem428(-32px);
          right: px2rem428(65px);
        }
        &.ann14-top-portal-3 {
          width: px2rem428(189px);
          height: px2rem428(203px);
          top: px2rem428(116px);
          left: px2rem428(33px);
        }
        &.ann14-top-portal-4 {
          width: px2rem428(189px);
          height: px2rem428(203px);
          top: px2rem428(69px);
          right: px2rem428(17px);
        }
        &.ann14-top-portal-5 {
          width: px2rem428(211px);
          height: px2rem428(225px);
          top: px2rem428(245px);
          left: px2rem428(3px);
        }
        &.ann14-top-portal-6 {
          width: px2rem428(211px);
          height: px2rem428(225px);
          top: px2rem428(238px);
          right: px2rem428(5px);
        }
        .ann14-top-portal-gif {
          width: px2rem428(135px);
          //border: 1px solid red;
          &.ann14-top-portal-gif-3,&.ann14-top-portal-gif-4 {
            width: px2rem428(154px);
          }
          &.ann14-top-portal-gif-5,&.ann14-top-portal-gif-6 {
            width: px2rem428(172px);
          }
        }
      }
    }
    .ann14-top-time-container {
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem428(84px);
      .ann14-top-time {
        width: px2rem428(400px);
        height: px2rem428(42px);
        font: normal normal normal px2rem428(14px)/px2rem428(19px) Microsoft YaHei;
        color: #0A2EA9;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #FFEB9C;
        border-radius: px2rem428(42px);
      }
    }
    .ann14-top-button-container {
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: px2rem428(61px);
      padding-left: px2rem428(44px);
      padding-right: px2rem428(43px);

      .ann14-top-button-deposit, .ann14-top-button-times {
        width: px2rem428(160px);
        height: px2rem428(60px);
        border: 1px solid #FFEB9C;
        border-radius: px2rem428(10px);
        font: normal normal normal px2rem428(14px)/px2rem428(19px) Microsoft YaHei;
        color: #FFEB9C;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ann14-top-button-deposit-row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: px2rem428(3px);
        }
      }

      .ann14-top-button-deposit{
        .ann14-top-button-deposit-refresh {
          width: px2rem428(18.4px);
          margin-left: px2rem428(9px);
        }
      }
    }
  }

  .ann14-middle {
    width: 100vw;
    height: px2rem428(373px);
    background-image: url($base-path + '/img/events/ann14/How-To_BG_Mobile.webp');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100vw px2rem428(373px);
    .ann14-middle-title-container {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: px2rem428(47px);
      .ann14-middle-title-1 {
        color: #FFEB9C;
        font: normal normal normal px2rem428(20px)/px2rem428(30px) Hiragino Sans GB;
      }
      .ann14-middle-title-2 {
        color: #A6B6EE;
        font: normal normal normal px2rem428(15px)/px2rem428(22px) Hiragino Sans GB;
        margin-top: px2rem428(3px);
      }
    }
    .ann14-middle-steps-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: px2rem428(27px);
      padding-left: px2rem428(15px);
      padding-right: px2rem428(15px);
      .ann14-middle-step {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &.ann14-middle-step1 {
          width: px2rem428(113px);
        }
        &.ann14-middle-step2 {
          width: px2rem428(153px);
        }
        &.ann14-middle-step3 {
          width: px2rem428(105px);
        }
        .ann14-middle-step-img {
          width: px2rem428(72px);
          height: px2rem428(71px);
        }
        .ann14-middle-step-title-1 {
          margin-top:px2rem428(17px);
          font: normal normal bold px2rem428(14px)/px2rem428(19px) Microsoft YaHei;
          color: #E5EBFF;
        }
        .ann14-middle-step-title-2 {
          font: normal normal normal px2rem428(12px)/px2rem428(16px) Microsoft YaHei;
          color: #A6B6EE;
        }
      }
    }
    .ann14-middle-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem428(32px);
      .ann14-middle-button {
        background-color: #EACF7A;
        border-radius: px2rem428(14px);
        width: px2rem428(194px);
        height: px2rem428(44px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font: normal normal bold px2rem428(15px)/px2rem428(20px) Microsoft YaHei;
        color: #0A2EA9;
      }
    }
  }

  .ann14-bottom {
    width: 100vw;
    height: px2rem428(806px);
    background-image: url($base-path + '/img/events/ann14/BG_Mobile-2.webp');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100vw px2rem428(806px);
    .ann14-bottom-title-container {
      width: 100vw;
      padding-top: px2rem428(43px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .ann14-bottom-title {
        width: px2rem428(209px);
        height: px2rem428(46px);
        background-image: url($base-path + '/img/events/ann14/Prize-Text.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font: normal normal normal px2rem428(20px)/px2rem428(30px) Hiragino Sans GB;
        color: #FFFFFF;
      }
    }
    .ann14-bottom-prizes-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: px2rem428(27px);
      padding-left: px2rem428(21px);
      padding-right: px2rem428(21px);
      .ann14-bottom-prize-img {
        width: px2rem428(94px);
        height: px2rem428(94px);
      }
    }
    .ann14-bottom-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem428(79px);
      .ann14-bottom-button {
        background-color: #EACF7A;
        border-radius: px2rem428(14px);
        width: px2rem428(194px);
        height: px2rem428(44px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font: normal normal bold px2rem428(15px)/px2rem428(20px) Microsoft YaHei;
        color: #0A2EA9;
      }
    }
  }
}

//彈窗共用背景block
.ann14-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.76);
}

//抽獎結果彈窗
.ann14-prize-popup {
  position: absolute;
  top: px2rem428(149px);
  left: px2rem428(7px);
  right: px2rem428(7px);
  border: none;
  width: px2rem428(414px);
  height: px2rem428(414px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .ann14-prize-popup-content {
    width: 100%;
    margin-top: px2rem428(96px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .ann14-prize-popup-content-title {
      font: normal normal bold px2rem428(15px)/px2rem428(20px) Microsoft YaHei;
      color: #003380;
    }
    .ann14-prize-popup-content-prize-img {
      width: px2rem428(106px);
      height: px2rem428(106px);
      margin-top: px2rem428(11px);
      margin-bottom: px2rem428(6px);
    }
    .ann14-prize-popup-content-text {
      font: normal normal normal px2rem428(10px)/px2rem428(14px) Microsoft YaHei;
      color: #003380;
      .ann14-prize-popup-content-text-highlight {
        color: #003CFF
      }
    }
    .ann14-prize-popup-buttons {
      width: px2rem428(280px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem428(17px);
      font: normal normal normal px2rem428(10px)/px2rem428(14px) Microsoft YaHei;
      .ann14-prize-popup-button-blue{
        width: px2rem428(141px);
        height: px2rem428(34px);
        background-image: url($base-path + '/img/events/ann14/Button-1.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #13479E;
      }
      .ann14-prize-popup-button-yellow{
        width: px2rem428(139px);
        height: px2rem428(34px);
        background-image: url($base-path + '/img/events/ann14/Button-2.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #403C01;
      }
    }
  }

}

//溫馨提醒彈窗
.ann14-top-popup {
  position: absolute;
  top: px2rem428(333px);
  left: px2rem428(46px);
  right: px2rem428(46px);
  border: none;
  background: #0C2274;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .ann14-top-popup-header {
    background-color: #EACF7A;
    width: 100%;
    height: px2rem428(37px);
    min-height: px2rem428(37px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: px2rem428(15px);
    padding-right: px2rem428(9px);
    font: normal normal bold px2rem428(15px)/px2rem428(20px) Microsoft YaHei;
    color: #0C2274;
    .ann14-top-popup-close {
      width: px2rem428(23px);
      height: px2rem428(23px);
    }
  }
  .ann14-top-popup-content {
    padding-top: px2rem428(17px);
    padding-left: px2rem428(15px);
    padding-right: px2rem428(17px);
    padding-bottom: px2rem428(22px);
    font: normal normal normal px2rem428(13px)/px2rem428(17px) Microsoft YaHei;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .ann14-top-popup-desc {
      text-align: center;
      width: px2rem428(304px);
      height: px2rem428(39px);
      .ann14-top-popup-desc-single {
        line-height: px2rem428(39px);
      }
    }
    .ann14-top-popup-buttons {
      width: px2rem428(304px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem428(10px);
      .ann14-top-popup-button-blue{
        width: px2rem428(135.43px);
        height: px2rem428(37px);
        background-color: #1285F5;
        border-radius: px2rem428(10px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .ann14-top-popup-button-yellow{
        width: px2rem428(135.43px);
        height: px2rem428(37px);
        background-color: #EACF7A;
        border-radius: px2rem428(10px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #0C2274;
      }
      .ann14-top-popup-button-sep {
        width: px2rem428(10px);
      }
    }
  }
}

//活動規則彈窗
.ann14-middle-popup {
  position: absolute;
  top: px2rem428(124px);
  left: px2rem428(34px);
  right: px2rem428(33px);
  bottom: px2rem428(174px);
  border: none;
  background: #0C2274;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .ann14-middle-popup-header {
    background-color: #EACF7A;
    width: 100%;
    height: px2rem428(50px);
    min-height: px2rem428(50px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: px2rem428(18px);
    padding-right: px2rem428(15px);
    font: normal normal bold px2rem428(16px)/px2rem428(21px) Microsoft YaHei;
    color: #0C2274;
    .ann14-middle-popup-close {
      width: px2rem428(30px);
      height: px2rem428(30px);
    }
  }
  .ann14-middle-popup-content {
    padding-top: px2rem428(18px);
    padding-left: px2rem428(8px);
    padding-right: px2rem428(26px);
    font: normal normal normal px2rem428(13px)/px2rem428(17px) Microsoft YaHei;
    color: #FFFFFF;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: px2rem428(6px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8F8F8F;
      border-radius: px2rem428(9px);
    }

    .ann14-middle-popup-table {
      width: px2rem428(320px);
      text-align: center;
      border-collapse: collapse;
      th {
        border: 2px solid #EACF7A;
        background-color: #EACF7A;
        font: normal normal normal px2rem428(9px)/px2rem428(12px) Microsoft YaHei;
        color: #0C2274;
        height:  px2rem428(20px);
        vertical-align:middle;
      }
      td {
        border: 2px solid #EACF7A;
        font: normal normal normal px2rem428(9px)/px2rem428(12px) Microsoft YaHei;
        color: #FFFFFF;
        height:  px2rem428(20px);
        vertical-align:middle;
      }
    }
  }
}

//我的中獎紀錄彈窗
.ann14-bottom-popup {
  position: absolute;
  top: px2rem428(223px);
  left: px2rem428(23px);
  right: px2rem428(23px);
  max-height: px2rem428(387px);
  border: none;
  background: #0C2274;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .ann14-bottom-popup-header {
    position: relative;
    background-color: #EACF7A;
    width: 100%;
    height: px2rem428(33px);
    min-height: px2rem428(33px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: normal normal bold px2rem428(16px)/px2rem428(21px) Microsoft YaHei;
    color: #0C2274;
    .ann14-bottom-popup-close {
      position: absolute;
      right: px2rem428(8px);
      width: px2rem428(23px);
      height: px2rem428(23px);
    }
  }
  .ann14-bottom-popup-content {
    padding-top: px2rem428(10px);
    padding-left: px2rem428(14px);
    padding-right: px2rem428(14px);
    padding-bottom: px2rem428(18px);
    font: normal normal normal px2rem428(10px)/px2rem428(12px) Microsoft YaHei;
    color: #FFFFFF;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: px2rem428(6px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8F8F8F;
      border-radius: px2rem428(9px);
    }

    .ann14-bottom-popup-table {
      width: px2rem428(335px);
      text-align: center;
      tr:nth-child(odd) {background-color: #2642AA;}
      tr:first-child {border-bottom: px2rem428(6px) solid #0C2274;}
      tr:nth-child(even) {background-color: #152E8B;}
      th:first-child { width: px2rem428(120px);}
      th {
        height:  px2rem428(30px);
        vertical-align:middle;
      }
      td {
        height:  px2rem428(30px);
        vertical-align:middle;
        border-bottom: px2rem428(2px) solid #0C2274;
      }
    }
  }
}

//debug 彈窗
.ann14-debug-entry {
  position: absolute;
  width: px2rem428(64px);
  height: px2rem428(64px);
  right: px2rem428(75px);
  bottom: px2rem428(80px);
}
.ann14-debug-popup {
  position: absolute;
  top: 2rem;
  left: 0.5rem;
  right: 0.5rem;
  max-height: calc(100vh - 2rem);
  border: none;
  background: #0C2274;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .ann14-debug-popup-header {
    background-color: #EACF7A;
    width: 100%;
    height: px2rem428(37px);
    min-height: px2rem428(37px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: px2rem428(15px);
    padding-right: px2rem428(9px);
    font: normal normal bold px2rem428(15px)/px2rem428(20px) Microsoft YaHei;
    color: #0C2274;
    .ann14-debug-popup-close {
      width: px2rem428(23px);
      height: px2rem428(23px);
    }
  }
  .ann14-debug-popup-content {
    width: 100%;
    padding-top: px2rem428(17px);
    padding-left: px2rem428(15px);
    padding-right: px2rem428(17px);
    padding-bottom: px2rem428(22px);
    font: normal normal normal px2rem428(13px)/px2rem428(17px) Microsoft YaHei;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    .ann14-debug-popup-content-input {
      width: px2rem428(250px);
      input {
        font-size: px2rem428(20px);
      }
    }
    .ann14-debug-popup-content-button {
      width: px2rem428(135.43px);
      min-height: px2rem428(37px);
      background-color: #1285F5;
      border-radius: px2rem428(10px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .ann14-debug-popup-content-hints {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .ann14-debug-popup-content-hint-button {
        width: px2rem428(30px);
        height: px2rem428(30px);
        margin: px2rem428(10px);
        background-color: #1285F5;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    .ann14-debug-popup-content-prizeResultTypes {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .ann14-debug-popup-content-prizeResultType-button {
        width: px2rem428(30px);
        height: px2rem428(30px);
        margin: px2rem428(10px);
        background-color: #1285F5;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &.selected {
          background-color: #EACF7A;
        }
      }
    }
    .ann14-debug-popup-content-prizeTypes {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .ann14-debug-popup-content-prizeType-button {
        width: px2rem428(80px);
        height: px2rem428(30px);
        margin: px2rem428(10px);
        background-color: #1285F5;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &.selected {
          background-color: #EACF7A;
        }
      }
    }
    .ann14-debug-popup-content-prize-button {
      width: px2rem428(135.43px);
      min-height: px2rem428(37px);
      background-color: #1285F5;
      border-radius: px2rem428(10px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
