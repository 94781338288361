@use "t";

.login__wrap {
	position: relative;
	height: 100%;
	.login__header__img {
		width: 100vw;
		height: 75.2vw;
	}
	.login__goBack {
		position: absolute;
		top: 13px;
		left: 8px;
		svg {
			width: 0.6933333333rem;
			height: 0.6933333333rem;
			vertical-align: -0.2rem;
			fill: #fff;
		}
	}

	.back-icon {
		position: absolute;
		top: 55px;
		left: 16px;
		line-height: inherit;
		z-index: 1000;
		svg {
			width: t.px2rem(26px);
			height: t.px2rem(26px);
			vertical-align: -0.2rem;
			fill: #000000f0;
		}
	}
	.login-cs-icon {
		position: absolute;
		top: 55px;
		right: 16px;
		line-height: inherit;
		z-index: 1000;
	}
}
.login__main {
	position: relative;
	bottom: 17px;
	z-index: 999;
	background: #fff;
	border-radius: 16px 16px 0 0;
	padding: 18px 16px 50px 16px;
	font-size: 14px;
	.login__forget {
		display: flex;
		justify-content: flex-end;
		.login__forget__button {
			margin-top: 18px;
			margin-bottom: 16px;
			color: $light-theme-color;
			font-size: 14px;
		}
	}
	.login__footer {
		display: flex;
		margin-top: 50px;
		.login__footer__box {
			display: flex;
			flex: 1;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			img {
				width: 58px;
				height: 58px;
			}
		}
		.login__footer__text {
			margin-top: 16px;
			color: #bcbec3;
			font-size: 12px;
			p {
				text-align: center;
			}
		}
	}
	.login__error {
		font-size: 14px;
		background: #ffe6e6;
		color: #eb2121;
		border-radius: 8px;
		padding: 10px 16px;
		margin-bottom: 24px;
		line-height: 20px;
	}
}
.login__pwd__open,
.login__pwd__close {
	position: absolute;
	top: 50%;
	right: 17px;
	transform: translateY(-50%);
	width: 16px;
}
.login__pwd__open {
	.a {
		opacity: 1;
	}
}
.login__pwd__close {
	.a {
		opacity: 0;
	}
}
.login__button {
	display: flex;
	margin-top: 24px;
	button {
		font-size: 16px;
		flex: 1;
		padding: 13px 0;
		border: 0;
		border-radius: 8px;
		color: #fff;
		background: $light-theme-color;
		&:focus {
			outline: none;
		}
	}
	.login__btn__register {
		margin-right: 7px;
		background: #0ccc3c;
	}
}
.btn-disable {
	color: #bcbec3 !important;
	background: #efeff4 !important;
}

.input__wrap {
	position: relative;
	&:not(:first-child) {
		margin-top: 16px;
	}
	.input__icon,
	.input__prefixText {
		position: absolute;
		transform: translateY(-50%);
	}
	.input__icon {
		top: 50%;
		left: 16px;
	}
	.input__prefixText {
		top: 48%;
		left: 45px;
		color: #bcbec3;
	}
	.clear-input-val {
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 18px;
		width: 18px;
		border-radius: 50%;
		transition: all 0.2s;
		opacity: 0;
		background: #dadada;
		text-align: center;
		&.show {
			opacity: 1;
		}
		svg {
			width: 8px;
			fill: #949494;
			height: 8px;
			vertical-align: middle;
		}
	}
	input {
		width: 100%;
		padding: 14.5px 12px;
		background: #fff;
		border: 0;
		border-radius: 8px;
		box-sizing: border-box;
		border: 1px solid #f3f3f3;
		transition: all 0.2s;
		outline: none;
		&:disabled {
			background: #f3f3f3;
		}
		&:focus {
			border-color: $light-theme-color;
			border-right-width: 1px !important;
			outline: 0;
			-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}
		&.input__withIcon {
			padding-left: 50px;
		}
		&.input__withBoth {
			padding-left: 85px;
		}
	}
}

.reg__main {
	font-size: 14px;

	.reg__submit {
		background: #0ccc3c;
		padding: 13px;
		text-align: center;
		border: 0;
		border-radius: 8px;
		color: #fff;
		margin-top: 8px;
	}

	.reg__rule {
		color: #999999;
		font-size: 12px;
		margin: 32px 0 16px 0;
		text-align: center;
		padding: 0 10px;
		line-height: 20px;
		a {
			color: $light-theme-color;
		}
	}
	.not-required-text {
		position: absolute;
		top: 50%;
		right: 17px;
		transform: translateY(-50%);
		color: #bcbec3;
	}
}

@text-box {
	padding: 10px 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
}

@mixin textBoxCommom($color, $bgcolor) {
	color: $color;
	background: $bgcolor;
	border-radius: 8px;
	padding: 10px 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
}

.warn-text {
	@include textBoxCommom(#eb2121,  #fee5e5);
	margin-top: 8px;
}

.notice-text {
	@include textBoxCommom(#666666,  #F7F7FC);
	margin-top: 8px;
}

.success-text {
	@include textBoxCommom(#0CCC3C,  #E4F7ED);
}

.loginSVG {
	// width: 18px;
	fill: #bcbec3;
	svg {
		width: 18px;
	}
}

.tabs__header {
	font-size: 14px;
}

.login__main .tabsOvalLogin-content-holder {
	margin-top: 26px;
}

.forgetpwd__main {
	height: 100%;
	background: #fff;
	.tabsOval-content-holder {
		padding: 24px 16px;
	}
	.login__button {
		padding: 0 16px;
		margin-top: 0;
	}
}

.forgetpwd-arrow-icon {
	svg {
		fill: #fff;
	}
}
