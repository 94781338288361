.verify-container {
	padding: 16px;
	font-size: 14px;
	.verify__topBox {
		padding: 24px 16px;
		background: #f7f7fc;
		border-radius: 8px;

		.verify__topBox__title {
			font-size: 20px;
		}

		.verify__topBox__content {
			font-size: 14px;
			line-height: 20px;
			margin: 16px 0;
		}

		.verify__topBox__btnWrap {
			display: flex;
			width: 100%;
			justify-content: flex-end;
			button {
				color: #00a6ff;
				border: 1px solid #00a6ff;
				padding: 10px 18.5px;
				border-radius: 8px;
				background: transparent;
				font-weight: bold;
			}
		}
	}

	.verify__main {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;
		.verify__main__item {
			text-align: center;
			background: #f7f7fc;
			border-radius: 8px;
			padding: 12px;
			&:not(:last-child) {
				margin-right: 10px;
			}
			button {
				background: #00a6ff;
				padding: 8px;
				color: #fff;
				border-radius: 4px;
				border: 0;
				font-size: 11px;
				margin-top: 4px;
				&.overtime-disabled {
					background: #e8e8e8;
					color: #bcbec3;
				}
			}
		}
	}
}

.verify__notice__modal.modal-dialog {
	height: 100%;
	height: 100%;
	width: 100%;
	border-radius: 0;
	top: 0;
	overflow: auto;

	.modal-info-content,
	.modal-info-title {
		padding: 0;
	}

	&.beforeDepositVerity {
		background-color: #F3F3F3;
	}
}

.securityNotice__main {
	padding: 32px 16px;
	font-size: 14px;
	color: #666666;
	line-height: 20px;
	.noticeSpace {
		height: 20px;
	}
}

.securityNotice__banner {
	width: 100%;
}

.underline_a {
	color: #00a6ff;
	text-decoration: underline;
	font-size: 14px;
}

.verifyPhoneMail__main {
	padding: 24px 16px;
	.verifyPhoneMail__heading {
		color: #000000;
		font-size: 16px;
		margin-bottom: 3px;
	}
	.verifyPhoneMail__text {
		color: #999999;
		font-size: 14px;
		line-height: 22px;
	}

	.verifyPhoneMail__input__heading,
	.verifyPhoneMail__input__text {
		line-height: 18px;
		font-size: 12px;
		text-align: center;
	}
	.verifyPhoneMail__input__heading {
		color: #000000;
		margin-bottom: 3px;
	}
	.verifyPhoneMail__input__text {
		color: #666666;
	}
	.verifyPhoneMail__line {
		background: #c4c4c4;
		height: 1px;
		width: 100%;
		margin-top: 25px;
		margin-bottom: 25px;
	}
	.verifyPhoneMail__phone__input {
		display: flex;
		margin: 12px 0;
	}
	.verifyPhoneMail__phone__prefix,
	.verifyPhoneMail__phone__number {
		height: 44px;
		display: flex;
		align-items: center;
		background: #efeff4;
		border-radius: 8px;
		font-size: 14px;
	}
	.verifyPhoneMail__phone__prefix {
		width: 44px;
		justify-content: center;
	}
	.verifyPhoneMail__phone__number {
		flex: 1;
		margin-left: 9px;
		padding: 0 16px;
	}
	.getVerfiyCodeBtn {
		font-size: 16px;
	}
	.verifyPhoneMail__notice {
		margin-top: 8px;
		background: #f7f7fc;
		width: 100%;
		padding: 15px 16px;
		border-radius: 8px;
		font-size: 12px;
		color: #666666;
		margin-bottom: 24px;
		.underline_a {
			font-size: 12px;
		}
	}
	.verifyPhoneMail__code {
		padding: 16px 23px;
		background: #efeff4;
		border-radius: 8px;
		margin-top: 25px;
		margin-bottom: 26px;
	}
	.verifyPhoneMail__code__input {
		display: flex;
		max-width: 300px;
		margin: 0 auto;
		&.codeError {
			.otp-input-container {
				input {
					border-color: #eb2121;
				}
			}
		}
	}
	.otp-input-container {
		justify-content: center;
		margin-top: 16px;
		width: 100%;
		// margin-bottom: 8px;
		> div {
			// width: 50px;
			flex: 1;
			justify-content: center;
		}
		input {
			border: 1px solid #d6d6d6;
			width: 0.878rem !important;
			height: 38px;
			background: #efeff4;
			border-radius: 4px;
			font-size: 16px;
			&:focus {
				outline: none;
			}
		}
	}
	.verifyBtn {
		margin-top: 17px;
		background: #0ccc3c !important;
		color: #fff !important;
		font-size: 16px;
	}
	.disabledBtn {
		background: #efeff4;
		color: #999999;
	}
	.disabledBtn2 {
		background: #d4d7dd !important;
		color: #999999 !important;
	}
	.progressbar_container {
		margin-bottom: 34px;
	}

	.BG_whiteColor_black {
		background: white !important;
		color: #222222 !important;
		font-size: 16px;
	}

	.BG_buleColor_white {
		background: #00A6FF !important;
		color: #fff !important;
		font-size: 16px;
	}

	.NameDisabledBtn {
		font-size: 16px;
		pointer-events: none;
	}


	.NameFocus {
		border: 1px solid red;
		border-radius: 8px;
		// box-shadow:0 0 0 1px rgb(255, 24, 24);
		& :focus {
			border:0;
			box-shadow:0 0 0 1px rgb(255, 24, 24);
			outline:0;
		}
	}
}

.text-center {
	text-align: center;
}

#ChangePwd {
	padding: 0 16px;
	.ChangePwd__notice {
		background: #fff5bf;
		color: #83630b;
		border-radius: 8px;
		padding: 12px 16px;
		width: 100%;
		line-height: 20px;
		margin-top: 24px;
		margin-bottom: 32px;
	}
	.ChangePwd___confirmInput {
		margin-top: 8px;
	}
}

.verify__overTime {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	width: 100%;
	.verify__overTime__title {
		font-size: 18px;
		font-weight: bold;
		margin-top: 24px;
		margin-bottom: 16px;
	}
	.verify__overTime__desc {
		color: #999999;
		font-size: 14px;
		line-height: 22px;
	}
	.footerBtn {
		display: flex;
		button {
			margin: 10px;
		}
		.whitebtn {
			margin: 10px;
			background: white;
			color: #00a6ff;
			border-color: #00a6ff;
			border: 1px solid #00a6ff;
		}
	}
}
