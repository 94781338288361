.rc-collapse {
	background-color: #f7f7f7;
	border-radius: 0.075rem;
	border: 0.025rem solid #d9d9d9;
}
.rc-collapse-anim-active {
	transition: height 0.2s ease-out;
}
.rc-collapse > .rc-collapse-item {
	//border-top: 0.025rem solid #d9d9d9;
	background: #efeff4;
	.rc-collapse-header {
		outline: none;
	}
}
.rc-collapse > .rc-collapse-item:first-child {
	border-top: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	line-height: 0.55rem;
	padding: 0.125rem 0;
	color: #666;
	cursor: pointer;
	justify-content: space-between;
	flex-flow: row-reverse;
	font-size: 0.35rem;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
	display: inline-block;
	content: '';
	transform: rotate(135deg);
	margin-right: 0.5rem;
	margin-top: -0.125rem;
	width: 0.25rem;
	height: 0.25rem;
	border-top: 0.05rem solid #999999;
	border-right: 0.05rem solid #999999;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
	margin: 0 0.4rem 0 auto;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
	cursor: not-allowed;
	color: #999;
	background-color: #f3f3f3;
}
.rc-collapse-content {
	overflow: hidden;
	color: #666;
	padding: 0;
	background-color: #fff;
}
.rc-collapse-content > .rc-collapse-content-box {
	// margin-top: 0.4rem;
	// margin-bottom: 0.4rem;
}
.rc-collapse-content-inactive {
	display: none;
}
.rc-collapse-item:last-child > .rc-collapse-content {
	border-radius: 0 0 0.075rem 0.075rem;
}
.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
	position: relative;
	width: 0.25rem;
	height: 0.25rem;
	border-top: 0.05rem solid #999999;
	border-right: 0.05rem solid #999999;
	transform: rotate(-45deg);
	margin-right: 0.5rem;
	margin-top: 0.125rem;
	vertical-align: middle;
}
