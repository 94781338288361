.pull-load {
	position: relative;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
.pull-load-head {
	position: absolute;
	transform: translate3d(0px, -100%, 0px);
	width: 100%;
}
.state-refreshing .pull-load-head,
.state-refreshed .pull-load-head {
	position: relative;
	transform: none;
}
.pull-load-body {
	position: relative;
}
.state-refreshing .pull-load-body {
	transition: transform 0.2s;
}
.state-reset .pull-load-body {
	transition: transform 0.2s;
}
/*
 * HeadNode default UI
 */
//.pull-load-head-default {
//	text-align: center;
//	font-size: 12px;
//	line-height: 3rem;
//	color: #999;
//}
//.state-pulling .pull-load-head-default:after {
//	content: '下拉刷新';
//}
//.state-pulling.enough .pull-load-head-default:after {
//	content: '松开刷新';
//}
//.state-refreshing .pull-load-head-default:after {
//	content: '正在刷新...';
//}
//.state-refreshed .pull-load-head-default:after {
//	content: '刷新成功';
//}
//.state-pulling .pull-load-head-default {
//	opacity: 1;
//}
//.state-pulling .pull-load-head-default i {
//	display: inline-block;
//	font-size: 2em;
//	margin-right: .6em;
//	vertical-align: middle;
//	height: 1em;
//	border-left: 1px solid;
//	position: relative;
//	transition: transform .3s ease;
//}
//.state-pulling .pull-load-head-default i:before,
//.state-pulling .pull-load-head-default i:after {
//	content: '';
//	position: absolute;
//	font-size: .5em;
//	width: 1em;
//	bottom: 0px;
//	border-top: 1px solid;
//}
//.state-pulling .pull-load-head-default i:before {
//	right: 1px;
//	transform: rotate(50deg);
//	transform-origin: right;
//}
//.state-pulling .pull-load-head-default i:after {
//	left: 0px;
//	transform: rotate(-50deg);
//	transform-origin: left;
//}
//.state-pulling.enough .pull-load-head-default i {
//	transform: rotate(180deg);
//}
//.state-refreshing .pull-load-head-default i {
//	margin-right: 10px;
//	display: inline-block;
//	vertical-align: middle;
//	font-size: 1.5rem;
//	width: 0.75rem;
//	height: 0.75rem;
//	border: 2px solid #00a6ff;
//	border-top-color: #fff;
//	border-radius: 100%;
//	animation: circle .8s infinite linear;
//}
//.state-refreshed .pull-load-head-default {
//	opacity: 1;
//	transition: opacity 1s;
//}
//.state-refreshed .pull-load-head-default i {
//	display: inline-block;
//	box-sizing: content-box;
//	vertical-align: middle;
//	margin-right: 10px;
//	font-size: 20px;
//	height: 1em;
//	width: 1em;
//	border: 1px solid;
//	border-radius: 100%;
//	position: relative;
//}
//.state-refreshed .pull-load-head-default i:before {
//	content: '';
//	position: absolute;
//	top: 3px;
//	left: 7px;
//	height: 11px;
//	width: 5px;
//	border: solid;
//	border-width: 0 1px 1px 0;
//	transform: rotate(40deg);
//}
.pull-load-footer-default {
	text-align: center;
	font-size: 0.3rem;
	line-height: 2rem;
	color: #999;
}
.state-loading .pull-load-footer-default:after {
	content: '加载更多';
}
.pull-load-footer-default.nomore:after {
	content: '没有更多了';
}
.state-loading .pull-load-footer-default i {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	font-size: 1.5rem;
	width: 0.75rem;
	height: 0.75rem;
	border: 2px solid #00a6ff;
	border-top-color: #fff;
	border-radius: 100%;
	animation: circle .8s infinite linear;
}
@keyframes circle {
	100% {
		transform: rotate(360deg);
	}
}
