.MiniGame {
	position: fixed;
	bottom: 0px;
	margin: auto;
	right: 5px;
	height: 200px;
	width: 128px;
	img {
		width: 100%;
	}
	.Close {
		width: 15px;
		height: 15px;
		background-image: url($base-path + '/svg/close.svg');
		background-repeat: NO-REPEAT;
		position: absolute;
		right: 5px;
		top: 35px;
		opacity: 0.5;
	}
}
