/* 主页 */
.Betting-skeleton {
	padding-top: 0.125rem;
	.skeleton-header {
		background-color: #e0e0e9;
		height: 3rem;
		margin: 0.25rem;
		border-radius: 0.25rem;
		text-align: center;
		position: relative;
		ul {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -0.3rem;
			display: flex;
			width: 1.625rem;
			li {
				width: 0.125rem;
				height: 0.125rem;
				background: #bcbec3;
				margin: 0 auto;
				border-radius: 0.5rem;
			}
			:first-child {
				width: 20px !important;
				background-color: #00a6ff;
				border-radius: 0.5rem;
			}
		}
	}
	.skeleton-header-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0.025rem solid #d7d8dd;
		padding-top: 0.25rem;
		.skeleton-header-nav-list {
			background-color: #e0e0e9;
			height: 0.75rem;
			margin: 0.25rem;
			border-radius: 0.5rem;
			text-align: center;
			width: 20%;
		}
	}
	.skeleton-games-menu {
		display: flex;
		padding: 0.25rem;
		align-items: center;
		.Yuan {
			background-color: #e0e0e9;
			border-radius: 0.5rem;
			height: 0.75rem;
			width: 0.75rem;
			margin-right: 0.25rem;
		}
	}
	.skeleton-games-list {
		background-color: white;
		height: 2.625rem;
		border-bottom: 0.025rem solid #efeff4;
		padding: 0.375rem;
		.content-list {
			display: flex;
			align-items: center;
			margin-bottom: 0.25rem;
		}
	}
	.TOPYuan {
		background-color: #e0e0e9;
		border-radius: 0.5rem;
		height: 0.75rem !important;
		width: 0.75rem !important;
		margin-right: 0.25rem;
	}
	.Yuan {
		background-color: #e0e0e9;
		border-radius: 0.5rem;
		height: 0.5rem;
		width: 0.5rem;
		margin-right: 0.25rem;
	}
	.Kuai {
		width: 2.5rem;
		background: #e0e0e9;
		height: 0.2rem;
		border-radius: 0.25rem;
	}
	.Right {
		display: flex;
		padding-left: 0.5rem;
		margin-left: 1rem;
		position: relative;
		ul {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -0.3rem;
			display: flex;
			width: 0.875rem;
			li {
				width: 0.5rem;
				height: 0.125rem;
				background: #bcbec3;
				margin: 0 auto;
				border-radius: 0.125rem;
			}
			:last-child {
				width: 0.125rem !important;
				border-radius: 0.5rem;
				background-color: #dcdce0;
			}
		}
		div {
			background-color: #efeff4;
			width: 2.05rem;
			height: 0.75rem;
			margin-right: 0.25rem;
			border-radius: 0.125rem;
		}
	}
}

/* 详情 */
.Betting-skeleton-detail {
	background: white;
	.skeleton-header {
		background-color: #e0e0e9;
		height: 5rem;
		text-align: center;
		position: relative;
	}
	.skeleton-header-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #efeff4;
		.skeleton-header-nav-list {
			background-color: #e0e0e9;
			height: 0.75rem;
			margin: 0.25rem;
			border-radius: 0.5rem;
			text-align: center;
			width: 20%;
		}
	}
	.one {
		height: 2rem !important;
	}
	.skeleton-content {
		background-color: white;
		.skeleton-games-list {
			background-color: #efeff4;
			height: 4.625rem;
			border-bottom: 0.025rem solid #efeff4;
			border-radius: 0.25rem;
			margin: 0.125rem;
		}
	}

	.Kuai {
		width: 2.5rem;
		background: #e0e0e9;
		height: 0.2rem;
		border-radius: 0.25rem;
	}
}

/* 投注动作板块 */
.Betting-skeleton-data-detail {
	background: white;
	position: absolute;
	width: 100%;
	top: 20%;
	z-index: 9;
	height: 100%;
	.Loading {
		position: absolute;
		left: 0;
		right: 0;
		top: 20%;
		width: 100%;
		height: 130px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9999;
		background: #ffffff00;
	}
	.BetingBtn {
		text-align: center;
		padding-top: 10%;
	}
	.BetingBtn .left {
		width: 20%;
	}
	.BetingBtn .right {
		width: 70%;
	}
	.BetingBtn button {
		background-color: #efeff4;
		padding: 20px;
		border: 0;
		margin: 10px 5px;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		color: #202939;
	}
	.Betlist {
		width: 100%;
		li {
			background-color: #efeff4;
			height: 160px;
			border-radius: 15px;
			margin: 10px;
		}
	}
}
