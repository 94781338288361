@use "t";
@use 'sass:math';
@import './theme/light-variable';
@import './theme/dark-variable';
@import './variable';

// Css 样式初始化
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
i {
	font-style: initial;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
	background-color: #efeff4;
	font-size: 41.4px;
	height: 100%;
	width: 100%;
}
body {
	height: 100%;
	font-family: "Helvetica Neue For Number", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC",
		"Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: t.px2rem(14px);
	// min-height: 100vh;
	min-width: 230px;
	max-width: $max-width;
	margin: auto;
	background-color: #efeff4;
}

body.scrolling-effect:not(.fullscreen-drawer) #__next {
	filter: blur(2px);
}
#__next {
	height: 100%;
	@include t.themify() using ($val) {
		color: t.themed($val, $light-font-color, $dark-font-color);
		background-color: t.themed($val, $light-bg-color, $dark-font-color);
	}
}
.a {
	opacity: 0;
}
.center {
	text-align: center;
}
.sport-container-wrapper {
	position: relative;
}
.sport-svg-wrap {
	display: inline-block;
	& > span {
		display: inherit;
	}
	svg {
		width: t.px2rem(26px);
		height: t.px2rem(26px);
		vertical-align: -0.22rem;
	}
}
.sport-sprite {
	background-image: url($base-path + '/img/sport_sprite.png?v=3.0');
	background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.sport-sprite {
		background: url($base-path + '/img/sport_sprite_retina.png?v=3.0') no-repeat;
		background-size: 406px 56px;
	}
}
.font-size-16 {
	font-size: t.px2rem(16px) !important;
}
.inline-block {
	display: inline-block;
}
.setting {
	position: absolute;
	bottom: 0;
}
.notification-message {
	color: #000;
	font-size: t.px2rem(12px);
	font-weight: bold;
	background-color: #ffe6dd;
	padding: 0 10px;
	height: 0px;
	line-height: 0px;
	position: relative;
	overflow: hidden;
	z-index: 2;
	& > img {
		width: 36px;
		height: 36px;
		display: inline-block;
		vertical-align: middle;
	}
	& > .notification-close-icon {
		float: right;
		svg {
			width: 16px;
			fill: #666;
		}
	}
	&.recommend {
		background-color: #cce5f6;
	}
	.notification-recommend-wrap {
		display: inline-block;
		vertical-align: middle;
		& > h4 {
			line-height: 1.4;
			max-width: calc(100vw - 100px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		& > p {
			color: #666;
			line-height: 1.4;
			max-width: calc(100vw - 100px);
			max-height: 0.5rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.notification-scale {
	&-appear,
	&-appear-active,
	&-appear-done,
	&-enter,
	&-enter-active,
	&-enter-done,
	&-exit,
	&-exit-active,
	&-exit-done {
		transform-origin: top;
		animation-duration: 0.16s;
		animation-fill-mode: both;
		animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
		animation-play-state: paused;
	}
	&-appear,
	&-appear-active,
	&-appear-done,
	&-enter,
	&-enter-active,
	&-enter-done {
		animation-name: notificationScaleFadeIn;
		animation-play-state: running;
	}
	&-exit,
	&-exit-active,
	&-exit-done {
		animation-name: notificationScaleFadeOut;
		animation-play-state: running;
	}
}
@keyframes notificationScaleFadeIn {
	0% {
		height: 0px;
		line-height: 0px;
	}
	100% {
		height: 50px;
		line-height: 50px;
	}
}
@keyframes notificationScaleFadeOut {
	0% {
		height: 50px;
		line-height: 50px;
	}
	100% {
		height: 0px;
		line-height: 0px;
	}
}
/* 足球加载动画 */
.Loading {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.2);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
	.laodingCon {
		width: 50px;
		height: 120px;
	}
	.ball {
		-webkit-animation: ball 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		-ms-animation: ball 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		-moz-animation: ball 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		animation: ball 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		height: 50px;
		width: 50px;
		background: url($base-path + '/img/loading-icon.png') no-repeat center;
		background-size: 100%;
	}
	.ballshadow {
		-webkit-animation: shadow 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		animation: shadow 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;
		background: #000;
		bottom: -90px;
		height: 15px;
		width: 50px;
		-webkit-animation-delay: 0;
		animation-delay: 0;
		border-radius: 100%;
	}
	@-webkit-keyframes ball {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-50px);
			transform: translateY(-50px);
		}
	}
	@keyframes ball {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-50px);
			transform: translateY(-50px);
		}
	}

	@-webkit-keyframes shadow {
		0% {
			opacity: 0.2;
			-webkit-transform: scale(0.3);
			transform: scale(0.3);
		}
		100% {
			opacity: 0.05;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	@keyframes shadow {
		0% {
			opacity: 0.2;
			-webkit-transform: scale(0.3);
			transform: scale(0.3);
		}
		100% {
			opacity: 0.05;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
}
.nextjs-toast-errors-parent {
	display: none;
}
@import '~rc-steps/assets/index.css';

@import 'select.css';
// @import 'rc-collapse.css';
@import 'button';
@import 'header';
@import 'home';
// 左侧滑动栏目
@import 'slider';

@import 'modal';
//首页投注
@import 'betting';
//手风琴
@import 'collapse';
@import 'login';
@import 'toast';
@import 'tabs';
@import 'setting';
@import 'deposit';
@import 'transfer';
@import 'rule';
@import 'other';
@import 'search';
@import 'bet-records.scss';
@import 'default-tabs.scss';
@import 'swiper/swiper.scss';
@import 'skeleton';
@import 'game-live.scss';
@import 'vendor-notice.scss';
@import 'tutorial';
// DatePicker
@import './../DatePickerCss/index.css';
@import './../DatePickerCss/date-picker.css';
@import './../DatePickerCss/popup.css';
@import 'verify';
@import 'progressbar';
@import 'information';

// 赛事详情动画
@import 'animation.css';

// DatePicker2
@import './date-picker/Calendar.css';
@import './date-picker/DateRangePicker.css';

//下拉刷新 上拉加载
@import 'reactpullLoad';

@import 'combo-bonus-detail';

@import 'notification-detail';

@import 'iconfont';

@import 'appdownload';

//赛事情报
@import 'smartcoach';

//游戏活动样式
@import 'minigame';

//提前兌現
@import 'cashout';

//自我限制彈窗
@import 'self-exclusion';

//原生活動
@import 'event_Anniversary';
