
.header-wrapper {
	width: 100%;
	height: t.px2rem($header-height);
	line-height: t.px2rem($header-height);
	padding: $left-right-distance-small;
	box-sizing: border-box;
	font-size: t.px2rem(15px);
	@include t.themify() using ($val) {
		color: t.themed($val, #fff, #000);
		background-color: t.themed($val, $light-theme-color, $light-theme-color);
	}
	.rc-tabs-nav-operations {
		height: initial;
		line-height: initial;
	}
	.tabs .rc-tabs-ink-bar {
		border-radius: t.px2rem(16px);
	}
	&.header-bar {
		text-align: center;
		position: relative;
		.header-tools-wrapper {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 10px;
			margin: auto;
		}
	}
	.back-icon, .down-arrow {
		position: absolute;
		left: 10px;
		line-height: inherit;
		z-index: 1000;
		svg {
			width: t.px2rem(26px);
			height: t.px2rem(26px);
			vertical-align: -0.2rem;
			fill: #fff;
		}
	}
	.header-menu-btn {
        display: inline-block;
        position: relative;
    }
    .unread-dot {
        width: 6px;
        height: 6px;
        background: red;
        position: absolute;
        right: 0;
        top: 18px;
        border-radius: 50%;
    }
	.header-game-select {
		display: inline-block;
		width: t.px2rem(90px);
		height: t.px2rem(40px);
		line-height: t.px2rem(40px);
		vertical-align: middle;
		overflow: hidden;
		margin: 0 t.px2rem(6px);
		position: relative;
		z-index: 100;
		border-radius: 8px;
		& > div {
			padding: 0 t.px2rem(8px);
			& > span {
				float: right;
			}
			&:first-child {
				border-top-right-radius: 8px;
				border-top-left-radius: 8px;
			}
			&:last-child {
				border-bottom-width: 0 !important;
				border-bottom-right-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}
		&.open {
			overflow: visible;
			background-color: #fff;
			color: #000;
			& > div {
				&:first-child {
					color: $light-theme-color;
				}
				background-color: #fff;
				border-bottom: 1px solid #ddd;
			}
			svg {
				fill: $light-theme-color;
			}
		}
		.header-select-svg {
			float: right;
		}
		svg {
			fill: #fff;
			vertical-align: -0.08rem;
			width: t.px2rem(20px);
			height: t.px2rem(20px);
		}
	}

	&.header-search {
		display: flex;
	}

	.header-search-wrapper {
		display: flex;
		align-items: center;
		margin-left: t.px2rem(30px);
		width:100%;
		> .input__wrap {
			width:100%;
			flex: 1;
			line-height: 1;
			> input {
				padding: 10px 12px;
				padding-left: 38px;
				border-radius: 20px;
			}
			> input::placeholder {
				color: #dadada;
			}
			.loginSVG svg {
				width: 14px;
			}
			.clear-input-val{
				opacity: unset;
				background-color: #EFEFF4;
				right: 70px;
				>.span {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 18px;
					height: 18px;
				}
			}
			.button-search {
				position: absolute;
				right: 2px;
				top: 0;
				bottom: 0;
				margin: auto;
				height: 33px;
				width: 52px;
				border-radius: 20px;
				background: #00A6FF;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
			}
		}
		& > span {
			flex-basis: 60px;
			text-align: center;
		}
	}
}
.maintain-box {
    color: #999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 14px;
    padding: 8px 6px !important;

	& > label {
		vertical-align: middle;
    }
	.count-box > span {
		font-size: 0.3rem;
		border: 1px solid #ddd;
		height: 0.6rem;
		line-height: 0.6rem;
		padding: 0 3px;
		white-space: nowrap;
		display: inline-block;
		border-radius: 4px;
		vertical-align: middle;
    }
    .count-box.game-maintenance{
        background: #FFEDA6;
        border: 1px solid #FFEDA6;
        color: #704708;
    }
}
.header-menu-svg svg {
	width: t.px2rem(26px);
	height: t.px2rem(26px);
}
.header-menu-svg svg,
.header-search-svg svg {
	fill: #fff;
	vertical-align: middle;
}
.header-eye-svg svg,
.header-money-icon-svg svg {
	fill: #fff;
	vertical-align: -0.02rem;
	margin-left: 10px;
}
.header-money-icon-svg svg,
.header-search-svg svg {
	width: t.px2rem(18px);
	height: t.px2rem(18px);
	vertical-align: -0.14rem;
	margin-right: 10px;
}
.header-eye-svg svg {
	width: t.px2rem(16px);
	height: t.px2rem(16px);
	position: absolute;
	right: t.px2rem(8px);
	top: 0;
	bottom: 0;
	margin: auto;
}
.sport-sprite.sport-service {
	width: 32px;
	height: 30px;
	vertical-align: middle;
	background-position: 1px 0;
}
.header-tools-wrapper {
	float: right;
	display: flex;
    align-items: center;
	& > div {
		display: inline-block;
	}
	.header-money {
		margin-right: t.px2rem(10px);
		width: t.px2rem(110px);
		overflow: hidden;
		height: 30px;
		line-height: 30px;
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 0 t.px2rem(8px);
		vertical-align: middle;
		position: relative;
		&.hide-box {
			width: 0.88rem;
		}
		i {
			font-size: t.px2rem(10px);
		}
		.sport-money {
			display: inline-block;
			padding-right: 16px;
			line-height: 1;
			width: 100%;
			box-sizing: border-box;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

// Fixed Header Style
.sport-container-wrapper.fixed-header {
	.header-wrapper {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 11;
		&.withAppDownloadbar {
			top: t.px2rem($app-download-bar-height);
		}
	}
	.sport-content {
		padding-top: t.px2rem($header-height);
		&.withAppDownloadbar {
			padding-top: t.px2rem($header-height+$app-download-bar-height);

			.Betting-nav {
				&.freeze {
					top: t.px2rem($header-height+$app-download-bar-height);
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.header-tools-wrapper .header-money {
		width: t.px2rem(70px);
	}
}

.maintenance-modal{
    .modal-info-content{
        text-align: center
    }
    .maintenance-title{
        font-size: 20px;
        font-weight: bold;
        margin-top: 16px;
    }
    .maintenance-desc{
        margin-top: 8px;
        font-size: 16px;
        color: #666666;
        line-height: 26px;
    }
}

.news-wrap{
    line-height: initial;
    .news-count{
        color: #fff;
        background: #EB2121;
        border-radius: 50%;
        padding: 0px 6.5px;
        height: 20px;
        line-height: 20px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}